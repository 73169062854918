@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Bold.woff2') format('woff2'),
        url('GeneralSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Italic.woff2') format('woff2'),
        url('GeneralSans-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Extralight.woff2') format('woff2'),
        url('GeneralSans-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-BoldItalic.woff2') format('woff2'),
        url('GeneralSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-ExtralightItalic.woff2') format('woff2'),
        url('GeneralSans-ExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Light.woff2') format('woff2'),
        url('GeneralSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Medium.woff2') format('woff2'),
        url('GeneralSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Semibold.woff2') format('woff2'),
        url('GeneralSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-LightItalic.woff2') format('woff2'),
        url('GeneralSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-SemiboldItalic.woff2') format('woff2'),
        url('GeneralSans-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Regular.woff2') format('woff2'),
        url('GeneralSans-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-MediumItalic.woff2') format('woff2'),
        url('GeneralSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}