.timeline a{
    position: relative;
}

.timeline a:not(:last-child)::after{
    content: '';
    width: 50%;
    background-color: #F0F6FA;
    height: 2px;
    position: absolute;
    right: 0%;
    top: 20%;
    z-index: -1;
}

.timeline a:not(:first-of-type)::before{
    content: '';
    width: 50%;
    background-color: #F0F6FA;
    height: 2px;
    position: absolute;
    left: 0%;
    top: 20%;
    z-index: -1;
}

.timeline .timeline-active::after{
    background-color: #0A6DA6 !important;
}

.timeline .timeline-active:not(:first-of-type)::after,
.timeline .timeline-active:not(:first-of-type)::before{
    background-color: #0A6DA6 !important;
}

.timeline .timeline-active h4{
    border-color: #0A6DA6 !important;
    color: #0A6DA6 !important;
}

.timeline .timeline-active p{
    color: #212121 !important;
}

@media (max-width: 1023px) {
    .timeline a:not(:last-child)::after,
    .timeline a:not(:first-of-type)::before{
        top: 17%;
    }
}

@media (max-width: 850px) {
    .timeline a p{
        max-width: 130px;
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .timeline a p{
        max-width: 100px;
    }
}

@media (max-width: 419px) {
    .timeline a p{
        max-width: 60px;
    }
}

.timeline a:active > p{
    color: #212121;
}